import * as React from "react";
import { Link } from "gatsby";
import { Head, MarkdownText } from "gatsby-theme-landing-page";
import "../styles.css";
import * as cssVars from "gatsby-theme-landing-page/src/styles/variables.module.css";
import * as styles from "./layout.module.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Layout(props) {
  return (
    <div className={[cssVars.root, styles.root].join(" ")}>
      <Head {...props} />
      <header className={styles.header}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 20,
          }}
        >
          <Link to="/">
            <GatsbyImage image={getImage(props.global.logo)} />
          </Link>
          <div
            className={styles.navigation}
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 20,
              marginLeft: "auto",
            }}
          >
            {props.links?.map((link) => (
              <Link to={`/${link.slug.split("/")[0]}`}>{link.name}</Link>
            ))}
          </div>
        </div>
      </header>
      <main className={styles.main}>{props.children}</main>
      <footer
        className={styles.footer}
        style={{
          backgroundColor: "hsl(0, 0%, 10%)",
          color: "white",
          padding: "50px 80px 20px 80px",
        }}
      >
        <div
          className={styles.content}
          style={{ display: "flex", flexWrap: "wrap", width: "100%", gap: 100 }}
        >
          <div style={{ width: "100%", flex: "1 0 0" }}>
            <GatsbyImage image={getImage(props.global.lightLogo)} />
            <div>
              <MarkdownText {...props.global.footerText} />
            </div>
          </div>
          <div style={{ flex: "1" }}>
            <div
              className={styles.navigation}
              style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
            >
              {props.links?.map((link) => (
                <Link to={`/${link.slug.split("/")[0]}`}>{link.name}</Link>
              ))}
            </div>
          </div>
        </div>
      </footer>
      <div
        style={{
          textAlign: "center",
          fontSize: 14,
          color: "white",
          padding: 16,
          backgroundColor: "hsl(0, 0%, 10%)",
        }}
      >
        Copyright © {new Date().getFullYear()} EpicWearCo. All Rights Reserved
      </div>
    </div>
  );
}
